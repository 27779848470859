import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";

function AOS() {
  const [scrollY, setScrollY] = useState(0);
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 100) {
      controls1.start({ opacity: 1, y: 0, transition: { duration: 0.8 } });
      controls2.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, delay: 0.2 },
      });
      controls3.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, delay: 0.4 },
      });
    } else {
      controls1.start({ opacity: 0, y: 100 });
      controls2.start({ opacity: 0, y: 100 });
      controls3.start({ opacity: 0, y: 100 });
    }
  }, [scrollY, controls1, controls2, controls3]);

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <br />
          <Typography
            sx={{
              color: "gray",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "24px",
              display: { xs: "block", md: "none" },
            }}
          >
            HERE'S WHAT YOU GET...
          </Typography>

          <Typography
            sx={{
              color: "gray",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "32px",
              display: { xs: "none", md: "block" },
            }}
          >
            HERE'S WHAT YOU GET...
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="App" style={{ textAlign: "center" }}>
            <motion.h1
              style={{ textAlign: "center", opacity: 0, y: 100 }}
              animate={controls1}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  display: { xs: "block", md: "none" },
                }}
              >
                Instagram Mastery
              </Typography>

              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "42px",
                  display: { xs: "none", md: "block" },
                }}
              >
                Instagram Mastery
              </Typography>
            </motion.h1>
            <br />
            <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls1}>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "none", md: "block" },
                  color: "grey",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Crafting </span> Instagram
                Success
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "block", md: "none" },
                  color: "grey",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Crafting </span> Instagram
                Success
              </Typography>
              <br />
            </motion.h1>
            <motion.h1
              style={{ color: "gray", opacity: 0, y: 100 }}
              animate={controls1}
            >
                Unleash the potential within our ebook, guiding you from
                inception to mastery.
                <br />
                <br />
                <br />
                Discover the art of building a robust Instagram presence and
                monetizing your unique brand effortlessly.
            </motion.h1>
          </div>
          <br />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="App" style={{ textAlign: "center", color: "grey" }}>
            <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls2}>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "none", md: "block" },
                  color: "grey",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Strategic </span> Content
                Dominance
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "block", md: "none" },
                  color: "grey",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Strategic </span> Content
                Dominance
              </Typography>
              <br />
            </motion.h1>
            <motion.h1
              style={{ color: "gray", opacity: 0, y: 100 }}
              animate={controls2}
            >
              Dive into the core of Instagram success with our proven
              strategies.
              <br />
              <br />
              <br />
              Master content creation, implement viral tactics, and witness an
              exponential growth in your followers and engagement.
            </motion.h1>
          </div>
          <br />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="App" style={{ textAlign: "center", color: "grey" }}>
            <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls3}>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "none", md: "block" },
                  color: "grey",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Monetize </span> with Impact
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  display: { xs: "block", md: "none" },
                  color: "grey",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "white" }}>Monetize </span> with Impact
              </Typography>
              <br />
            </motion.h1>
            <motion.h1
              style={{ color: "gray", opacity: 0, y: 100 }}
              animate={controls3}
            >
              Scale your Instagram reach strategically and convert followers
              into revenue.
              <br />
              <br />
              <br />
              Our ebook provides the roadmap for seamlessly navigating
              Instagram's algorithm, turning your influence into a powerful
              income stream.
            </motion.h1>
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "block", justifyContent: "center" }}
        >
          <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls3}>
            <Typography
              sx={{
                textAlign: "center",
                display: { xs: "none", md: "block" },
                fontWeight: "bold",
                fontSize: "32px",
              }}
            >
              100+ millions views generated
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                display: { xs: "block", md: "none" },
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              100+ millions views generated
            </Typography>
          </motion.h1>
        </Grid>
      </Grid>
      <br />
      <br />
      <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls3}>
        <Typography
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "26px",
            display: { xs: "block", md: "none" },
            color: "grey",
          }}
        >
          Testimonials
        </Typography>
      </motion.h1>
      <motion.h2 style={{ opacity: 0, y: 100 }} animate={controls3}>
        <Typography
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "32px",
            display: { xs: "none", md: "block" },
            color: "grey",
          }}
        >
          Testimonials
        </Typography>
        <br />
      </motion.h2>

      <motion.h1 style={{ opacity: 0, y: 100 }} animate={controls3}>
        <Typography
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "22px",
            display: { xs: "block", md: "none" },
          }}
        >
          Stories from successful people
        </Typography>
      </motion.h1>
      <motion.h2 style={{ opacity: 0, y: 100 }} animate={controls3}>
        <Typography
          sx={{
            textAlign: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "32px",
            display: { xs: "none", md: "block" },
          }}
        >
          Stories from successful people
        </Typography>
        <br />
      </motion.h2>
    </Container>
  );
}

export default AOS;