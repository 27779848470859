import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';

export default function ButtonAppBar() {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography sx={{ fontFamily:"PT Serif, serif !important",fontWeight: 'bold', fontSize:"18px", display: { xs: 'flex', md: 'none' } }} align="center" color="white">
                Millionaire Mission
              </Typography>
              <Typography sx={{ fontFamily:"PT Serif, serif !important",fontWeight: 'bold', fontSize:"38px", display: { xs: 'none', md: 'flex' } }} align="center" color="white">
                Millionaire Mission
              </Typography>
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={handleDrawerClose}
        onEscapeKeyDown={handleDrawerClose}
        TransitionComponent={Slide}
        transitionDuration={500}
        ModalProps={{
          onClick: handleDrawerClose,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        <List>
          <ListItem button component="a" href="#FAQ">
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem button component="a" href="#OK">
            <ListItemText primary="Stories of Success" />
          </ListItem>
          <ListItem button component="a" href="#IBG">
            <ListItemText primary="Instagram Mastery" />
          </ListItem>
          <ListItem button onClick={handleDrawerClose}>
            <ListItemText primary="Close" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}