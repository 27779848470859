import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@mui/material";
import "lazysizes";

export default function newCard(props) {
  return (
    <Card
      color="transparent"
      variant="gradient"
      style={{ border: "2px solid white" }}
    >
      <CardBody>
        <Typography
          style={{ color: "white", textAlign: "center", fontSize: "20px",fontWeight: "bold",textShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)"}}
        >
          {props.head}
        </Typography>

        <Divider sx={{ bgcolor: "white" }} />
        <br />
        <Typography
          sx={{
            color: "white",
            textShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            fontSize: "20px",
            marginLeft: "7px",
          }}
          variant="paragraph"
        >
          <span style={{ color: "white" }}>&#10004; </span>
          <span
            style={{ color: "grey", fontWeight: "normal", fontSize: "14px" }}
          >
            {props.t1}
          </span>
        </Typography>
        <br />
        <Typography
          sx={{
            color: "white",
            textShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            fontSize: "20px",
            marginLeft: "7px",
          }}
          variant="paragraph"
        >
          {" "}
          <span style={{ color: "white" }}>&#10004; </span>
          <span
            style={{ color: "grey", fontWeight: "normal", fontSize: "14px" }}
          >
            {props.t2}
          </span>
        </Typography>
        <br />
        <Typography
          sx={{
            color: "white",
            textShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            fontSize: "20px",
            marginLeft: "7px",
          }}
          variant="paragraph"
        >
          {" "}
          <span style={{ color: "white" }}>&#10004; </span>
          <span
            style={{ color: "grey", fontWeight: "normal", fontSize: "14px" }}
          >
            {props.t3}
          </span>
        </Typography>
      </CardBody>
      <CardHeader floated={false} color="transparent">
        <img
          src={props.img}
          className="lazyload"
          alt="card"
          style={{ marginBottom: "16px", borderRadius: "6px" }}
        />
      </CardHeader>
    </Card>
  );
}