import React from "react";

const footerStyles = {
  position: "sticky",
  bottom: 0, 
  width: "100%", 
  backgroundColor: "black",
  color: "white",
  padding: "20px",
  textAlign: "center",
  zIndex: 1000, 
};

export default function StickyFooter() {
  return (
    <footer style={footerStyles}>
      <a href="https://millionairemissionn.gumroad.com/l/guide?wanted=true" style={{cursor:"pointer"}}>
       Grab your guide for FREE now !
      </a>
    </footer>
  );
}
