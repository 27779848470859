import React from "react";
import { Grid, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "./carousel.css";
import CarouselImage0 from "./images/carouselImage0.PNG";
import CarouselImageParts0 from "./images/CarouselImageParts0.svg";
import DiamondTwoToneIcon from "@mui/icons-material/DiamondTwoTone";
import "lazysizes";

export default function CarouselMain(props) {
  return (
    <>
      <br />
      <br />
      <br />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <img
            className="lazyload"
            alt="Showing a book for instagram business success guide"
            src={CarouselImage0}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
            }}
          >
            {[...Array(5)].map((_, index) => (
              <StarIcon key={index} style={{ color: "yellow" }} />
            ))}
          </div>
          <br />
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: "21px",
              display: { xs: "block", md: "none" },
            }}
          >
            Instagram Mastery
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              display: { xs: "block", md: "none" },
              color: "grey",
              marginTop: "10px",
            }}
          >
            FREE
          </Typography>

          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: "42px",
              display: { xs: "none", md: "block" },
            }}
          >
            Instagram Mastery
          </Typography>
          <Typography
            sx={{
              fontSize: "28px",
              display: {
                xs: "none",
                md: "block",
                color: "grey",
                marginTop: "10px",
              },
            }}
          >
            FREE
          </Typography>
          <br />
          <button className="bu">Here's what you will learn</button>
          <br />
          <br />
          <Typography variant="body2" style={{ color: "gray" }}>
            *by purchasing the latest Instagram Mastery today, you'll
            gain access to everything you need like starting and scaling
            business online in real time.
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "18px",
              display: { xs: "block", md: "none" },
            }}
          >
            ✔ Page Setup Mastery
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "24px",
              display: { xs: "none", md: "block" },
            }}
          >
            ✔ Page Setup Mastery
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              color: "grey",
              fontSize: "15px",
              display: { xs: "block", md: "none" },
            }}
          >
            Effortlessly set up your Instagram page following our proven
            strategies. Our quick-start guide ensures seamless implementation
            for instant impact.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", display: { xs: "none", md: "block" } }}
          >
            Effortlessly set up your Instagram page following our proven
            strategies. Our quick-start guide ensures seamless implementation
            for instant impact.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "18px",
              display: { xs: "block", md: "none" },
            }}
          >
            ✔ AI Content Creation Revolution
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "24px",
              display: { xs: "none", md: "block" },
            }}
          >
            ✔ AI Content Creation Revolution
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              color: "grey",
              fontSize: "15px",
              display: { xs: "block", md: "none" },
            }}
          >
            Revolutionize content creation with AI Voice Customization. Craft a
            unique voice, automate the process, and stay ahead in the Instagram
            game.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", display: { xs: "none", md: "block" } }}
          >
            Revolutionize content creation with AI Voice Customization. Craft a
            unique voice, automate the process, and stay ahead in the Instagram
            game.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "18px",
              display: { xs: "block", md: "none" },
            }}
          >
            ✔ Instagram Algorithm Decoded
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "24px",
              display: { xs: "none", md: "block" },
            }}
          >
            ✔ Instagram Algorithm Decoded
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              color: "grey",
              fontSize: "15px",
              display: { xs: "block", md: "none" },
            }}
          >
            Uncover the secrets behind Instagram's algorithm and learn how to
            leverage it for maximum visibility.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", display: { xs: "none", md: "block" } }}
          >
            Uncover the secrets behind Instagram's algorithm and learn how to
            leverage it for maximum visibility.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "18px",
              display: { xs: "block", md: "none" },
            }}
          >
            ✔ Monetization Blueprint
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "24px",
              display: { xs: "none", md: "block" },
            }}
          >
            ✔ Monetization Blueprint
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              color: "grey",
              fontSize: "15px",
              display: { xs: "block", md: "none" },
            }}
          >
            Navigate the path to earning on Instagram with our comprehensive
            guide, Understand the nuances of selling on Instagram, and kickstart
            your earning potential with proven methods outlined by Millionaire
            Mission.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", display: { xs: "none", md: "block" } }}
          >
            Navigate the path to earning on Instagram with our comprehensive
            guide, Understand the nuances of selling on Instagram, and kickstart
            your earning potential with proven methods outlined by Millionaire
            Mission.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "18px",
              display: { xs: "block", md: "none" },
            }}
          >
            🎁 Exclusive Bonuses
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "medium",
              fontSize: "24px",
              display: { xs: "none", md: "block" },
            }}
          >
            🎁 Exclusive Bonuses
          </Typography>
          <br />
          <Typography
            variant="h3"
            sx={{
              color: "grey",
              fontSize: "15px",
              display: { xs: "block", md: "none" },
            }}
          >
            <DiamondTwoToneIcon style={{ color: "white" }} /> Notion Template
            <br />
            <DiamondTwoToneIcon style={{ color: "white" }} /> Discord Community
            Access
            <br />
            <DiamondTwoToneIcon style={{ color: "white" }} /> Additional
            Resources and Perks
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "grey", display: { xs: "none", md: "block" } }}
          >
            <DiamondTwoToneIcon style={{ color: "white" }} /> Notion Template
            <br />
            <DiamondTwoToneIcon style={{ color: "white" }} /> Discord Community
            Access
            <br />
            <DiamondTwoToneIcon style={{ color: "white" }} /> Additional
            Resources and Perks
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={12} justifyContent="center" alignItems="center">
        <Grid item sx={{ color: "black" }}>
          <div class="flex justify-center items-center h-full">
            <div class="w-full md:w-1/2 p-4 relative">
              <a
                title="Get quote now"
                class="relative inline-flex items-center justify-center px-6 md:px-8 py-3 md:py-4 text-lg font-bold text-white transition-all duration-200 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 w-full"
                role="button"
                href="https://millionairemissionn.gumroad.com/l/guide?wanted=true"
              >
                Grab your Guide FREE here!
              </a>
            </div>
          </div>

          <img
            className="lazyload"
            alt="Showing features of the book"
            src={CarouselImageParts0}
          />
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
}