import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function MultipleAccordions() {
  const accordionStyle = {
    backgroundColor: "black",
    color: "grey",
    border: "2px solid white",
    borderRadius: "20px",
    marginBottom: "8px",
    borderImageSlice: "20",
  };
  const summaryStyle = { color: "white" };

  return (
    <div>
      <Accordion style={accordionStyle}>
        <AccordionSummary
          expandIcon={<AddIcon style={summaryStyle} />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={summaryStyle}
        >
          <Typography
            sx={{ display: { xs: "none", md: "block" } }}
            variant="body1"
          >
            What You'll Learn Inside the Instagram Mastery
          </Typography>
          <Typography
            sx={{ display: { xs: "block", md: "none" } }}
            variant="body2"
          >
            What You'll Learn Inside the Instagram Mastery
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "medium",
              color: "grey",
              display: { xs: "none", md: "block" },
            }}
          >
            • You will learn the fundamental principles of successful Instagram
            marketing.
            <br />
            <br />
            • You will learn the insider secrets behind Instagram's algorithm,
            empowering you to boost visibility.
            <br />
            <br />
            • You will learn actionable strategies to effectively decode and
            navigate the intricacies of the Instagram algorithm.
            <br />
            <br />
            • You will learn proven techniques to monetize your Instagram
            presence strategically.
            <br />
            <br />
            • You will learn how to harness the power of hashtags to amplify the
            impact of your Instagram Reels.
            <br />
            <br />• You will learn the art of crafting captions that captivate
            and enhance engagement on your Instagram Reel content.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "medium",
              color: "grey",
              display: { xs: "block", md: "none" },
            }}
          >
            • You will learn the fundamental principles of successful Instagram
            marketing.
            <br />
            <br />
            • You will learn the insider secrets behind Instagram's algorithm,
            empowering you to boost visibility.
            <br />
            <br />
            • You will learn actionable strategies to effectively decode and
            navigate the intricacies of the Instagram algorithm.
            <br />
            <br />
            • You will learn proven techniques to monetize your Instagram
            presence strategically.
            <br />
            <br />
            • You will learn how to harness the power of hashtags to amplify the
            impact of your Instagram Reels.
            <br />
            <br />• You will learn the art of crafting captions that captivate
            and enhance engagement on your Instagram Reel content.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion style={accordionStyle}>
        <AccordionSummary
          expandIcon={<AddIcon style={summaryStyle} />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={summaryStyle}
        >
          <Typography
            sx={{ display: { xs: "none", md: "block" } }}
            variant="body1"
          >
            {" "}
            What Happens After Purchasing the Instagram Mastery
          </Typography>
          <Typography
            sx={{ display: { xs: "block", md: "none" } }}
            variant="body2"
          >
            {" "}
            What Happens After Purchasing the Instagram Mastery
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "medium",
              color: "grey",
              display: { xs: "none", md: "block" },
            }}
          >
            • Following your purchase, you'll receive instant access to the
            Instagram Mastery via email.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "medium",
              color: "grey",
              display: { xs: "block", md: "none" },
            }}
          >
            • Following your purchase, you'll receive instant access to the
            Instagram Mastery via email.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}