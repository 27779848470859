import FAQ from "./components/faq/faq.jsx";
import Cardloop from "./components/cards/cardloop.jsx";
import {Container, Grid } from "@mui/material";
import AOS from "./components/onscroll/onscroll.jsx";
import CarouselMain from "./components/carousel/carousel.jsx";
import Navbar from "./components/NavigationBar/navbar.jsx";
import Footer from "./components/footer/footer.jsx";
import StickyFooter from "./components/footer/StickyFooter.jsx";
import Loading from "./components/Loader/loading.jsx";

import React, { useState, useEffect } from 'react';

function App() {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {showLoading && <Loading />}
      {!showLoading && (
        <>
          <Navbar />
          <Container>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ maxWidth: '1200px' }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} id="carouselSection">
                <CarouselMain />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} id="FAQ">
                <FAQ />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} id="IBG">
                <AOS />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} id="OK">
                <Cardloop />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Footer />
              </Grid>
            </Grid>
          </Container>
          <StickyFooter />
        </>
      )}
    </div>
  );
}

export default App;