import React from "react";
import Grid from "@mui/material/Grid";
import { Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "black",
  ...theme.typography.body2,
  textAlign: "center",
  color: "grey",
}));

const currentYear = new Date().getFullYear(); // Get the current year

const Footer = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Grid
      container
      spacing={6}
      justifyContent="center"
      sx={{ backgroundColor: "black", color: "white", paddingTop: "20px" }}
    >
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
            Instagram Mastery
          </Typography>
          <Item
            sx={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => scrollToSection("carouselSection")}
          >
            Home
          </Item>
          <Item
            sx={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => scrollToSection("FAQ")}
          >
            Frequently Asked Questions
          </Item>
          <Item
            sx={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => scrollToSection("IBG")}
          >
            Stories of successful people
          </Item>
          <Item
            sx={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => scrollToSection("insta")}
          >
            Instagram Mastery
          </Item>
        </Stack>
      </Grid>
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            Instagram Mastery
          </Typography>
          <Item
            sx={{ fontSize: "16px", cursor: "pointer" }}
            onClick={() => scrollToSection("carouselSection")}
          >
            Home
          </Item>
          <Item
            sx={{ fontSize: "16px", cursor: "pointer" }}
            onClick={() => scrollToSection("FAQ")}
          >
            Frequently Asked Questions
          </Item>
          <Item
            sx={{ fontSize: "16px", cursor: "pointer" }}
            onClick={() => scrollToSection("OK")}
          >
            Stories from successful people
          </Item>
          <Item
            sx={{ fontSize: "16px", cursor: "pointer" }}
            onClick={() => scrollToSection("IBG")}
          >
            Instagram Mastery
          </Item>
        </Stack>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
            Socials
          </Typography>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            color="inherit"
            href="https://www.instagram.com/millionaire.mission_?igsh=MTlhOWxxYjZ4dGIyeA=="
          >
            <Item sx={{ fontSize: "12px", cursor: "pointer" }}>Instagram</Item>
          </Link>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            color="inherit"
            href="mailto:someone@example.com"
            onClick={() => (window.location = "mailto:yourmail@domain.com")}
          >
            <Item sx={{ fontSize: "12px", cursor: "pointer" }}>Email</Item>
          </Link>
        </Stack>
      </Grid>
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-end"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            Socials
          </Typography>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            color="inherit"
            href="https://www.instagram.com/millionaire.mission_?igsh=MTlhOWxxYjZ4dGIyeA=="
          >
            <Item sx={{ fontSize: "16px", cursor: "pointer" }}>Instagram</Item>
          </Link>
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            color="inherit"
            href="mailto:someone@example.com"
            onClick={() => (window.location = "mailto:yourmail@domain.com")}
          >
            <Item sx={{ fontSize: "16px", cursor: "pointer" }}>Email</Item>
          </Link>
        </Stack>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Typography sx={{ fontWeight: "normal", fontSize: "12px" }}>
            © {currentYear} Millionaire Mission
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Typography sx={{ fontWeight: "normal", fontSize: "16px" }}>
            © {currentYear} Millionaire Mission
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;