import {
  Card,
  CardHeader,
} from "@material-tailwind/react";
import 'lazysizes';

export default function Cards(props) {
  return (
    <Card
      color="transparent"
      variant="gradient"
    >
      <CardHeader floated={false} color="transparent">
        <img
          src={props.img}
          className="lazyload"
          alt="card"
        />
      </CardHeader>
    </Card>
  );
}