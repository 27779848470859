import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Cards from "./cards";
import { motion, useAnimation } from "framer-motion";
import NewCard from "./newCard.jsx";
import CardImgAwaiting0 from './images/awaiting/1.avif';
import CardImgAwaiting1 from './images/awaiting/2.avif';
import CardImgAwaiting2 from './images/awaiting/3.avif';
import CardImgTestimonials0 from './images/testimonials/1.avif';
import CardImgTestimonials1 from './images/testimonials/2.avif';
import CardImgTestimonials2 from './images/testimonials/3.avif';
import CardImgTestimonials3 from './images/testimonials/4.avif';
import CardImgTestimonials4 from './images/testimonials/5.avif';
import 'lazysizes';

const names = ["Karthik", "Danny", "Sophia", "Daniel", "Alex"];
const desc = [
  "Simple, easy to understand, and covers everything I needed to skyrocket my Instagram presence. Highly recommend!",
  "Loving the black and white theme! This ebook is not just informative but also visually appealing. A great combination for a complete learning experience",
  "Easy to learn, clear explanations about how Instagram's algorithm works, and much more",
  "Perfectly guided on how to monetize and scale my page. The explanations are top-notch, making it a valuable resource for anyone looking to make money on Instagram.",
  "From mastering content creation to understanding the intricacies of Instagram's algorithm, this ebook is a must-have for serious Instagram growth. Added bonus",
];

const imagesawaiting = [
  CardImgAwaiting0,
  CardImgAwaiting1,
  CardImgAwaiting2
];

const imagestestimonials = [
  CardImgTestimonials0,
  CardImgTestimonials1,
  CardImgTestimonials2,
  CardImgTestimonials3,
  CardImgTestimonials4
];

const heads = ["Personalized Growth Blueprint", "Exclusive Insider Strategies", "Elite Community Connection"];
const tx1 = ["Uncover a step-by-step roadmap tailored to your Instagram journey", "Systematically transform your approach for personalized growth", "Align the blueprint with your unique goals and aspirations"];
const tx2 = ["Dive into the Instagram Mastery for insider tactics", "Explore advanced content creation methods for exceptional results", "Master the Instagram algorithm with unconventional strategies"];
const tx3 = ["Immerse yourself in the Millionaire's Mission Discord Communit", "Access individualized mentorship and connect with accomplished creators", "Leverage unique networking opportunities for distinctive growth and achievement"];

export default function Cardloop() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cardControls = useAnimation();
  const newCardControls = useAnimation();

  useEffect(() => {
    const triggerAnimation = () => {
      const windowHeight = window.innerHeight;
      const cardTopOffset = document.getElementById("cards-container").offsetTop - windowHeight + 100;
      const newCardTopOffset = document.getElementById("new-cards-container").offsetTop - windowHeight + 100;

      if (scrollY > cardTopOffset) {
        cardControls.start({ opacity: 1, y: 0, transition: { duration: 1 } });
      } else {
        cardControls.start({ opacity: 0, y: 100 });
      }

      if (scrollY > newCardTopOffset) {
        newCardControls.start({ opacity: 1, y: 0, transition: { duration: 1 } });
      } else {
        newCardControls.start({ opacity: 0, y: 100 });
      }
    };

    window.addEventListener("scroll", triggerAnimation, { passive: true });

    return () => {
      window.removeEventListener("scroll", triggerAnimation);
    };
  }, [scrollY, cardControls, newCardControls]);

  return (
    <Grid container spacing={6} justifyContent="center" sx={{ maxWidth: "1200px" }}>
      {[...Array(5)].map((_, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={4} id="cards-container">
          <motion.div animate={cardControls}>
            <Cards key={index} name={names[index]} desc={desc[index]} img={imagestestimonials[index]} />
          </motion.div>
        </Grid>
      ))}


      <Grid item xs={12}>
        <motion.h1 animate={newCardControls}>
          <div className="App" style={{ textAlign: "center", color: "white" }}>

            <Typography sx={{ color: "gray", textAlign: "center", fontSize: "20px", display: { xs: 'block', md: 'none' } }} >What Awaits You<br /></Typography>

            <Typography sx={{ color: "gray", textAlign: "center", fontWeight: 'bold', fontSize: "32px", display: { xs: 'none', md: 'block' } }} >What Awaits You<br /></Typography>

            <Typography sx={{ fontWeight: 'bold', fontSize: "22px", display: { xs: 'block', md: 'none' } }} >Inside the Instagram Mastery</Typography>

            <Typography sx={{ fontWeight: 'bold', fontSize: "42px", display: { xs: 'none', md: 'block' } }} >Inside the Instagram Mastery</Typography>

          </div>
        </motion.h1>
      </Grid>

      {[...Array(3)].map((_, index) => (
        <Grid item key={index} xs={12} sm={4} md={4} lg={4} id="new-cards-container" >
          <motion.div animate={newCardControls}>
            <NewCard
              key={index}
              head={heads[index]}
              t1={tx1[index]}
              t2={tx2[index]}
              t3={tx3[index]}
              img={imagesawaiting[index]}
            />
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}